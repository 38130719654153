import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, Inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { AnalyticsService, ApplicationContext, DialogService } from '@core/services';
import { Product } from '@models/context/product';
import { ConfirmationDialogComponent } from '@organisms/confirmation-dialog/confirmation-dialog.component';
import { BehaviorSubject, Observable, Subject, fromEvent, merge } from 'rxjs';
import { debounceTime, filter, mergeMap, takeUntil } from 'rxjs/operators';
import { FAVICON_CONFIG } from './configs/favicon.config';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  public IN_NATIVE_APP: boolean;
  public hasGivenChoice$: BehaviorSubject<boolean>;
  public isEmbed = window.location.pathname.startsWith('/embed'); // ActivatedRoute is not available on top of the first router outlet.

  private readonly SHOW_MODE_INACTIVITY_TIME = 60000;
  private onDestroySubject$ = new Subject<void>();

  constructor(
    icons: MatIconRegistry,
    sanitizer: DomSanitizer,
    @Inject(DOCUMENT) private readonly document: Document,
    private analyticsService: AnalyticsService,
    private context: ApplicationContext,
    private dialog: MatDialog,
    private dialogService: DialogService,
    private renderer: Renderer2,
    private router: Router,
    private swUpdate: SwUpdate
  ) {
    icons.addSvgIcon(
      'comment',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-comment.svg')
    );
    icons.addSvgIcon(
      'destination',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-destination.svg')
    );
    icons.addSvgIcon(
      'discover',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-discover.svg')
    );
    icons.addSvgIcon(
      'explorer',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-explorer.svg')
    );
    icons.addSvgIcon(
      'feedback',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-feedback.svg')
    );
    icons.addSvgIcon(
      'instagram-logo',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-instagram.svg')
    );
    icons.addSvgIcon(
      'roadmap',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-roadmap.svg')
    );
    icons.addSvgIcon(
      'school',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-school.svg')
    );
    icons.addSvgIcon(
      'question-mark',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-circle-question-mark.svg')
    );
    icons.addSvgIcon(
      'situation',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-situation.svg')
    );
    icons.addSvgIcon(
      'twitter-logo',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-twitter.svg')
    );
    icons.addSvgIcon(
      'linkedin-logo',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-linkedin.svg')
    );
    icons.addSvgIcon(
      'parcoursup',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-parcoursup.svg')
    );
    icons.addSvgIcon(
      'profile',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-profile.svg')
    );
    icons.addSvgIcon(
      'settings',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-settings.svg')
    );
    icons.addSvgIcon(
      'lady-poi-avatar-1',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-lady-poi-avatar-1.svg')
    );
    icons.addSvgIcon(
      'lady-poi-avatar-2',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-lady-poi-avatar-2.svg')
    );
    icons.addSvgIcon(
      'lady-poi-avatar-3',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-lady-poi-avatar-3.svg')
    );
    icons.addSvgIcon(
      'lady-poi-avatar-4',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-lady-poi-avatar-4.svg')
    );
    icons.addSvgIcon(
      'lady-poi-avatar-5',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-lady-poi-avatar-5.svg')
    );
    icons.addSvgIcon(
      'man-poi-avatar-1',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-man-poi-avatar-1.svg')
    );
    icons.addSvgIcon(
      'man-poi-avatar-2',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-man-poi-avatar-2.svg')
    );
    icons.addSvgIcon(
      'man-poi-avatar-3',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-man-poi-avatar-3.svg')
    );
    icons.addSvgIcon(
      'man-poi-avatar-4',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-man-poi-avatar-4.svg')
    );
    icons.addSvgIcon(
      'man-poi-avatar-5',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-man-poi-avatar-5.svg')
    );
    icons.addSvgIcon(
      'whatsapp',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-whatsapp.svg')
    );
    icons.addSvgIcon('user', sanitizer.bypassSecurityTrustResourceUrl('assets/img/user.svg'));
    // map
    icons.addSvgIcon(
      'pin-diploma',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-pin-diploma.svg')
    );
    icons.addSvgIcon(
      'pin-job',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-pin-job.svg')
    );
    icons.addSvgIcon(
      'pin-village',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/pin-village.svg')
    );
    icons.addSvgIcon(
      'pin-village-custom',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/pin-village-custom.svg')
    );
    icons.addSvgIcon(
      'pin-village-selected',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/pin-village-selected.svg')
    );
    icons.addSvgIcon(
      'pin-village-departure',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/pin-village-departure.svg')
    );
    icons.addSvgIcon(
      'pin-village-destination',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/pin-village-destination.svg')
    );
    icons.addSvgIcon(
      'pin-village-green',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/pin-village-green.svg')
    );
    icons.addSvgIcon(
      'pin-village-empty',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/pin-village-empty.svg')
    );
    icons.addSvgIcon('line', sanitizer.bypassSecurityTrustResourceUrl('assets/img/line.svg'));
    icons.addSvgIcon('gold', sanitizer.bypassSecurityTrustResourceUrl('assets/img/gold.svg'));
    icons.addSvgIcon(
      'info',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-info.svg')
    );
    icons.addSvgIcon(
      'error',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-error.svg')
    );
    icons.addSvgIcon(
      'warning',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-warning.svg')
    );
    icons.addSvgIcon(
      'success',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-success.svg')
    );
    icons.addSvgIcon('dots', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/dots.svg'));
    icons.addSvgIcon(
      'trash-bin',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/trash-bin.svg')
    );

    // Statuses
    icons.addSvgIcon(
      'student-lady',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/student-lady.svg')
    );
    icons.addSvgIcon(
      'student-man',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/student-man.svg')
    );
    icons.addSvgIcon(
      'unemployed-lady',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/unemployed-lady.svg')
    );
    icons.addSvgIcon(
      'unemployed-man',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/unemployed-man.svg')
    );
    icons.addSvgIcon(
      'pro-lady',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/pro-lady.svg')
    );
    icons.addSvgIcon('pro-man', sanitizer.bypassSecurityTrustResourceUrl('assets/img/pro-man.svg'));
    icons.addSvgIcon(
      'highschool-lady',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/highschool-lady.svg')
    );
    icons.addSvgIcon(
      'highschool-man',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/highschool-man.svg')
    );
    icons.addSvgIcon(
      'middleschool-lady',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/middleschool-lady.svg')
    );
    icons.addSvgIcon(
      'middleschool-man',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/middleschool-man.svg')
    );
  }

  public ngOnInit(): void {
    this.IN_NATIVE_APP = this.context.inNativeApp;
    this.hasGivenChoice$ = this.analyticsService.hasGivenChoice$;

    this.analyticsService.init().pipe(takeUntil(this.onDestroySubject$)).subscribe();

    if (this.context.product === Product.Show) {
      const window: Window = this.document.defaultView;

      merge(fromEvent(window, 'mousemove'), fromEvent(window, 'scroll'), fromEvent(window, 'click'))
        .pipe(
          debounceTime(this.SHOW_MODE_INACTIVITY_TIME),
          filter(() => this.router.url !== '/'),
          mergeMap(() => this.showInactivityDialog()),
          takeUntil(this.onDestroySubject$)
        )
        .subscribe(confirmed => {
          if (confirmed === false) this.router.navigateByUrl('/');
        });
    }

    this.handleAppUpdate();
  }

  public ngOnDestroy(): void {
    this.onDestroySubject$.next();
    this.onDestroySubject$.complete();
  }

  public ngAfterViewInit(): void {
    const newFavIcon = FAVICON_CONFIG(this.context.applicationName);

    if (newFavIcon)
      this.renderer.setAttribute(this.document.querySelector('#favicon'), 'href', newFavIcon);
  }

  private showInactivityDialog(): Observable<boolean | undefined> {
    return this.dialog
      .open(
        ConfirmationDialogComponent,
        this.dialogService.simpleDialogConfig({
          titleKey: 'inactivity-dialog.title',
          confirmButtonLabelKey: 'inactivity-dialog.confirm',
          cancelButtonLabelKey: 'inactivity-dialog.cancel'
        })
      )
      .afterClosed();
  }

  // TODO : add activateUpdate feature
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private handleAppUpdate(): any {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.versionUpdates.pipe(takeUntil(this.onDestroySubject$)).subscribe(evt => {
        // eslint-disable-next-line default-case
        switch (evt.type) {
          case 'VERSION_DETECTED':
            console.log(`Downloading new app version: ${evt.version.hash}`);

            // this.swUpdate.activateUpdate().then(() => {
            //   this.document.defaultView.location.reload();
            //   console.log('Reload');
            // });
            break;

          case 'VERSION_READY':
            // TODO : add cta to trigger new version installation
            console.log(`Current app version: ${evt.currentVersion.hash}`);
            console.log(`New app version ready for use: ${evt.latestVersion.hash}`);
            break;

          case 'VERSION_INSTALLATION_FAILED':
            console.log(`Failed to install app version '${evt.version.hash}': ${evt.error}`);
            break;
        }
      });
    }
  }
}
