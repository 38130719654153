import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { APP_INITIALIZER, Injector, LOCALE_ID, NgModule, Optional, SkipSelf } from '@angular/core';
import { MAT_LEGACY_TABS_CONFIG as MAT_TABS_CONFIG } from '@angular/material/legacy-tabs';
import { HammerModule } from '@angular/platform-browser';
import { initApp } from '@core/helpers/app-pre-boot-initializer';
import { setRootInjector } from '@core/helpers/root-injector';
import { createTranslateLoader } from '@core/helpers/translate-http-loader';
import { initializerFactory } from '@core/helpers/translation-initializer-factory';
import { AcceptLanguageInterceptor } from '@core/http/accept-language.interceptor';
import { ContextInterceptor } from '@core/http/context-interceptor/context.interceptor';
import { EncodeParamsInterceptor } from '@core/http/encode-params.interceptor';
import { RequestInterceptor } from '@core/http/request.interceptor';
import { UnsafeTokenInterceptor } from '@core/http/unsafe-token.interceptor';
import { ApplicationContext, AuthService, SituationContext } from '@core/services';
import { DynamicLocaleId } from '@models/dynamic-locale-id/dynamic-locale-id';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { AuthModule } from './auth/auth.module';
import { DayjsModule } from './dayjs/dayjs.module';
import { SentryModule } from './sentry/sentry.module';

@NgModule({
  imports: [
    AuthModule.forRoot(),
    DayjsModule,
    HammerModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    SentryModule
  ],
  providers: [
    { provide: LOCALE_ID, useClass: DynamicLocaleId, deps: [TranslateService] },
    {
      provide: APP_INITIALIZER,
      useFactory: initApp,
      deps: [ApplicationContext, AuthService, SituationContext],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializerFactory,
      deps: [Injector, TranslateService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UnsafeTokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: EncodeParamsInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AcceptLanguageInterceptor,
      deps: [TranslateService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ContextInterceptor,
      deps: [ApplicationContext],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true
    },
    { provide: MAT_TABS_CONFIG, useValue: { animationDuration: '0ms' } }
  ]
})
export class CoreModule {
  // NOTE: See https://angular.io/guide/singleton-services#prevent-reimport-of-the-greetingmodule
  constructor(@Optional() @SkipSelf() parentModule: CoreModule, private injector: Injector) {
    if (parentModule) {
      throw new Error(
        `${parentModule.constructor.name} has already been loaded. Import this module in the AppModule only.`
      );
    }

    // use for create custom typescript decorators
    setRootInjector(this.injector);
  }
}
