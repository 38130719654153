import { __assign } from "tslib";
import { dropUndefinedKeys, timestampWithMs, uuid4 } from '@sentry/utils';
/**
 * Keeps track of finished spans for a given transaction
 * @internal
 * @hideconstructor
 * @hidden
 */
var SpanRecorder = /** @class */function () {
  function SpanRecorder(maxlen) {
    if (maxlen === void 0) {
      maxlen = 1000;
    }
    this.spans = [];
    this._maxlen = maxlen;
  }
  /**
   * This is just so that we don't run out of memory while recording a lot
   * of spans. At some point we just stop and flush out the start of the
   * trace tree (i.e.the first n spans with the smallest
   * start_timestamp).
   */
  SpanRecorder.prototype.add = function (span) {
    if (this.spans.length > this._maxlen) {
      span.spanRecorder = undefined;
    } else {
      this.spans.push(span);
    }
  };
  return SpanRecorder;
}();
export { SpanRecorder };
/**
 * Span contains all data about a span
 */
var Span = /** @class */function () {
  /**
   * You should never call the constructor manually, always use `Sentry.startTransaction()`
   * or call `startChild()` on an existing span.
   * @internal
   * @hideconstructor
   * @hidden
   */
  function Span(spanContext) {
    /**
     * @inheritDoc
     */
    this.traceId = uuid4();
    /**
     * @inheritDoc
     */
    this.spanId = uuid4().substring(16);
    /**
     * Timestamp in seconds when the span was created.
     */
    this.startTimestamp = timestampWithMs();
    /**
     * @inheritDoc
     */
    this.tags = {};
    /**
     * @inheritDoc
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.data = {};
    if (!spanContext) {
      return this;
    }
    if (spanContext.traceId) {
      this.traceId = spanContext.traceId;
    }
    if (spanContext.spanId) {
      this.spanId = spanContext.spanId;
    }
    if (spanContext.parentSpanId) {
      this.parentSpanId = spanContext.parentSpanId;
    }
    // We want to include booleans as well here
    if ('sampled' in spanContext) {
      this.sampled = spanContext.sampled;
    }
    if (spanContext.op) {
      this.op = spanContext.op;
    }
    if (spanContext.description) {
      this.description = spanContext.description;
    }
    if (spanContext.data) {
      this.data = spanContext.data;
    }
    if (spanContext.tags) {
      this.tags = spanContext.tags;
    }
    if (spanContext.status) {
      this.status = spanContext.status;
    }
    if (spanContext.startTimestamp) {
      this.startTimestamp = spanContext.startTimestamp;
    }
    if (spanContext.endTimestamp) {
      this.endTimestamp = spanContext.endTimestamp;
    }
  }
  /**
   * @inheritDoc
   * @deprecated
   */
  Span.prototype.child = function (spanContext) {
    return this.startChild(spanContext);
  };
  /**
   * @inheritDoc
   */
  Span.prototype.startChild = function (spanContext) {
    var childSpan = new Span(__assign(__assign({}, spanContext), {
      parentSpanId: this.spanId,
      sampled: this.sampled,
      traceId: this.traceId
    }));
    childSpan.spanRecorder = this.spanRecorder;
    if (childSpan.spanRecorder) {
      childSpan.spanRecorder.add(childSpan);
    }
    childSpan.transaction = this.transaction;
    return childSpan;
  };
  /**
   * @inheritDoc
   */
  Span.prototype.setTag = function (key, value) {
    var _a;
    this.tags = __assign(__assign({}, this.tags), (_a = {}, _a[key] = value, _a));
    return this;
  };
  /**
   * @inheritDoc
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  Span.prototype.setData = function (key, value) {
    var _a;
    this.data = __assign(__assign({}, this.data), (_a = {}, _a[key] = value, _a));
    return this;
  };
  /**
   * @inheritDoc
   */
  Span.prototype.setStatus = function (value) {
    this.status = value;
    return this;
  };
  /**
   * @inheritDoc
   */
  Span.prototype.setHttpStatus = function (httpStatus) {
    this.setTag('http.status_code', String(httpStatus));
    var spanStatus = spanStatusfromHttpCode(httpStatus);
    if (spanStatus !== 'unknown_error') {
      this.setStatus(spanStatus);
    }
    return this;
  };
  /**
   * @inheritDoc
   */
  Span.prototype.isSuccess = function () {
    return this.status === 'ok';
  };
  /**
   * @inheritDoc
   */
  Span.prototype.finish = function (endTimestamp) {
    this.endTimestamp = typeof endTimestamp === 'number' ? endTimestamp : timestampWithMs();
  };
  /**
   * @inheritDoc
   */
  Span.prototype.toTraceparent = function () {
    var sampledString = '';
    if (this.sampled !== undefined) {
      sampledString = this.sampled ? '-1' : '-0';
    }
    return this.traceId + "-" + this.spanId + sampledString;
  };
  /**
   * @inheritDoc
   */
  Span.prototype.toContext = function () {
    return dropUndefinedKeys({
      data: this.data,
      description: this.description,
      endTimestamp: this.endTimestamp,
      op: this.op,
      parentSpanId: this.parentSpanId,
      sampled: this.sampled,
      spanId: this.spanId,
      startTimestamp: this.startTimestamp,
      status: this.status,
      tags: this.tags,
      traceId: this.traceId
    });
  };
  /**
   * @inheritDoc
   */
  Span.prototype.updateWithContext = function (spanContext) {
    var _a, _b, _c, _d, _e;
    this.data = (_a = spanContext.data, _a !== null && _a !== void 0 ? _a : {});
    this.description = spanContext.description;
    this.endTimestamp = spanContext.endTimestamp;
    this.op = spanContext.op;
    this.parentSpanId = spanContext.parentSpanId;
    this.sampled = spanContext.sampled;
    this.spanId = (_b = spanContext.spanId, _b !== null && _b !== void 0 ? _b : this.spanId);
    this.startTimestamp = (_c = spanContext.startTimestamp, _c !== null && _c !== void 0 ? _c : this.startTimestamp);
    this.status = spanContext.status;
    this.tags = (_d = spanContext.tags, _d !== null && _d !== void 0 ? _d : {});
    this.traceId = (_e = spanContext.traceId, _e !== null && _e !== void 0 ? _e : this.traceId);
    return this;
  };
  /**
   * @inheritDoc
   */
  Span.prototype.getTraceContext = function () {
    return dropUndefinedKeys({
      data: Object.keys(this.data).length > 0 ? this.data : undefined,
      description: this.description,
      op: this.op,
      parent_span_id: this.parentSpanId,
      span_id: this.spanId,
      status: this.status,
      tags: Object.keys(this.tags).length > 0 ? this.tags : undefined,
      trace_id: this.traceId
    });
  };
  /**
   * @inheritDoc
   */
  Span.prototype.toJSON = function () {
    return dropUndefinedKeys({
      data: Object.keys(this.data).length > 0 ? this.data : undefined,
      description: this.description,
      op: this.op,
      parent_span_id: this.parentSpanId,
      span_id: this.spanId,
      start_timestamp: this.startTimestamp,
      status: this.status,
      tags: Object.keys(this.tags).length > 0 ? this.tags : undefined,
      timestamp: this.endTimestamp,
      trace_id: this.traceId
    });
  };
  return Span;
}();
export { Span };
/**
 * Converts a HTTP status code into a {@link SpanStatusType}.
 *
 * @param httpStatus The HTTP response status code.
 * @returns The span status or unknown_error.
 */
export function spanStatusfromHttpCode(httpStatus) {
  if (httpStatus < 400 && httpStatus >= 100) {
    return 'ok';
  }
  if (httpStatus >= 400 && httpStatus < 500) {
    switch (httpStatus) {
      case 401:
        return 'unauthenticated';
      case 403:
        return 'permission_denied';
      case 404:
        return 'not_found';
      case 409:
        return 'already_exists';
      case 413:
        return 'failed_precondition';
      case 429:
        return 'resource_exhausted';
      default:
        return 'invalid_argument';
    }
  }
  if (httpStatus >= 500 && httpStatus < 600) {
    switch (httpStatus) {
      case 501:
        return 'unimplemented';
      case 503:
        return 'unavailable';
      case 504:
        return 'deadline_exceeded';
      default:
        return 'internal_error';
    }
  }
  return 'unknown_error';
}
