import { __extends, __values } from "tslib";
import { logger, timestampWithMs } from '@sentry/utils';
import { FINISH_REASON_TAG, IDLE_TRANSACTION_FINISH_REASONS } from './constants';
import { IS_DEBUG_BUILD } from './flags';
import { SpanRecorder } from './span';
import { Transaction } from './transaction';
export var DEFAULT_IDLE_TIMEOUT = 1000;
export var HEARTBEAT_INTERVAL = 5000;
/**
 * @inheritDoc
 */
var IdleTransactionSpanRecorder = /** @class */function (_super) {
  __extends(IdleTransactionSpanRecorder, _super);
  function IdleTransactionSpanRecorder(_pushActivity, _popActivity, transactionSpanId, maxlen) {
    if (transactionSpanId === void 0) {
      transactionSpanId = '';
    }
    var _this = _super.call(this, maxlen) || this;
    _this._pushActivity = _pushActivity;
    _this._popActivity = _popActivity;
    _this.transactionSpanId = transactionSpanId;
    return _this;
  }
  /**
   * @inheritDoc
   */
  IdleTransactionSpanRecorder.prototype.add = function (span) {
    var _this = this;
    // We should make sure we do not push and pop activities for
    // the transaction that this span recorder belongs to.
    if (span.spanId !== this.transactionSpanId) {
      // We patch span.finish() to pop an activity after setting an endTimestamp.
      span.finish = function (endTimestamp) {
        span.endTimestamp = typeof endTimestamp === 'number' ? endTimestamp : timestampWithMs();
        _this._popActivity(span.spanId);
      };
      // We should only push new activities if the span does not have an end timestamp.
      if (span.endTimestamp === undefined) {
        this._pushActivity(span.spanId);
      }
    }
    _super.prototype.add.call(this, span);
  };
  return IdleTransactionSpanRecorder;
}(SpanRecorder);
export { IdleTransactionSpanRecorder };
/**
 * An IdleTransaction is a transaction that automatically finishes. It does this by tracking child spans as activities.
 * You can have multiple IdleTransactions active, but if the `onScope` option is specified, the idle transaction will
 * put itself on the scope on creation.
 */
var IdleTransaction = /** @class */function (_super) {
  __extends(IdleTransaction, _super);
  function IdleTransaction(transactionContext, _idleHub,
  /**
   * The time to wait in ms until the idle transaction will be finished.
   * @default 1000
   */
  _idleTimeout,
  // Whether or not the transaction should put itself on the scope when it starts and pop itself off when it ends
  _onScope) {
    if (_idleTimeout === void 0) {
      _idleTimeout = DEFAULT_IDLE_TIMEOUT;
    }
    if (_onScope === void 0) {
      _onScope = false;
    }
    var _this = _super.call(this, transactionContext, _idleHub) || this;
    _this._idleHub = _idleHub;
    _this._idleTimeout = _idleTimeout;
    _this._onScope = _onScope;
    // Activities store a list of active spans
    _this.activities = {};
    // Amount of times heartbeat has counted. Will cause transaction to finish after 3 beats.
    _this._heartbeatCounter = 0;
    // We should not use heartbeat if we finished a transaction
    _this._finished = false;
    _this._beforeFinishCallbacks = [];
    if (_idleHub && _onScope) {
      // There should only be one active transaction on the scope
      clearActiveTransaction(_idleHub);
      // We set the transaction here on the scope so error events pick up the trace
      // context and attach it to the error.
      IS_DEBUG_BUILD && logger.log("Setting idle transaction on scope. Span ID: " + _this.spanId);
      _idleHub.configureScope(function (scope) {
        return scope.setSpan(_this);
      });
    }
    _this._initTimeout = setTimeout(function () {
      if (!_this._finished) {
        _this.finish();
      }
    }, _this._idleTimeout);
    return _this;
  }
  /** {@inheritDoc} */
  IdleTransaction.prototype.finish = function (endTimestamp) {
    var e_1, _a;
    var _this = this;
    if (endTimestamp === void 0) {
      endTimestamp = timestampWithMs();
    }
    this._finished = true;
    this.activities = {};
    if (this.spanRecorder) {
      IS_DEBUG_BUILD && logger.log('[Tracing] finishing IdleTransaction', new Date(endTimestamp * 1000).toISOString(), this.op);
      try {
        for (var _b = __values(this._beforeFinishCallbacks), _c = _b.next(); !_c.done; _c = _b.next()) {
          var callback = _c.value;
          callback(this, endTimestamp);
        }
      } catch (e_1_1) {
        e_1 = {
          error: e_1_1
        };
      } finally {
        try {
          if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
        } finally {
          if (e_1) throw e_1.error;
        }
      }
      this.spanRecorder.spans = this.spanRecorder.spans.filter(function (span) {
        // If we are dealing with the transaction itself, we just return it
        if (span.spanId === _this.spanId) {
          return true;
        }
        // We cancel all pending spans with status "cancelled" to indicate the idle transaction was finished early
        if (!span.endTimestamp) {
          span.endTimestamp = endTimestamp;
          span.setStatus('cancelled');
          IS_DEBUG_BUILD && logger.log('[Tracing] cancelling span since transaction ended early', JSON.stringify(span, undefined, 2));
        }
        var keepSpan = span.startTimestamp < endTimestamp;
        if (!keepSpan) {
          IS_DEBUG_BUILD && logger.log('[Tracing] discarding Span since it happened after Transaction was finished', JSON.stringify(span, undefined, 2));
        }
        return keepSpan;
      });
      IS_DEBUG_BUILD && logger.log('[Tracing] flushing IdleTransaction');
    } else {
      IS_DEBUG_BUILD && logger.log('[Tracing] No active IdleTransaction');
    }
    // if `this._onScope` is `true`, the transaction put itself on the scope when it started
    if (this._onScope) {
      clearActiveTransaction(this._idleHub);
    }
    return _super.prototype.finish.call(this, endTimestamp);
  };
  /**
   * Register a callback function that gets excecuted before the transaction finishes.
   * Useful for cleanup or if you want to add any additional spans based on current context.
   *
   * This is exposed because users have no other way of running something before an idle transaction
   * finishes.
   */
  IdleTransaction.prototype.registerBeforeFinishCallback = function (callback) {
    this._beforeFinishCallbacks.push(callback);
  };
  /**
   * @inheritDoc
   */
  IdleTransaction.prototype.initSpanRecorder = function (maxlen) {
    var _this = this;
    if (!this.spanRecorder) {
      var pushActivity = function (id) {
        if (_this._finished) {
          return;
        }
        _this._pushActivity(id);
      };
      var popActivity = function (id) {
        if (_this._finished) {
          return;
        }
        _this._popActivity(id);
      };
      this.spanRecorder = new IdleTransactionSpanRecorder(pushActivity, popActivity, this.spanId, maxlen);
      // Start heartbeat so that transactions do not run forever.
      IS_DEBUG_BUILD && logger.log('Starting heartbeat');
      this._pingHeartbeat();
    }
    this.spanRecorder.add(this);
  };
  /**
   * Start tracking a specific activity.
   * @param spanId The span id that represents the activity
   */
  IdleTransaction.prototype._pushActivity = function (spanId) {
    if (this._initTimeout) {
      clearTimeout(this._initTimeout);
      this._initTimeout = undefined;
    }
    IS_DEBUG_BUILD && logger.log("[Tracing] pushActivity: " + spanId);
    this.activities[spanId] = true;
    IS_DEBUG_BUILD && logger.log('[Tracing] new activities count', Object.keys(this.activities).length);
  };
  /**
   * Remove an activity from usage
   * @param spanId The span id that represents the activity
   */
  IdleTransaction.prototype._popActivity = function (spanId) {
    var _this = this;
    if (this.activities[spanId]) {
      IS_DEBUG_BUILD && logger.log("[Tracing] popActivity " + spanId);
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      delete this.activities[spanId];
      IS_DEBUG_BUILD && logger.log('[Tracing] new activities count', Object.keys(this.activities).length);
    }
    if (Object.keys(this.activities).length === 0) {
      var timeout = this._idleTimeout;
      // We need to add the timeout here to have the real endtimestamp of the transaction
      // Remember timestampWithMs is in seconds, timeout is in ms
      var end_1 = timestampWithMs() + timeout / 1000;
      setTimeout(function () {
        if (!_this._finished) {
          _this.setTag(FINISH_REASON_TAG, IDLE_TRANSACTION_FINISH_REASONS[1]);
          _this.finish(end_1);
        }
      }, timeout);
    }
  };
  /**
   * Checks when entries of this.activities are not changing for 3 beats.
   * If this occurs we finish the transaction.
   */
  IdleTransaction.prototype._beat = function () {
    // We should not be running heartbeat if the idle transaction is finished.
    if (this._finished) {
      return;
    }
    var heartbeatString = Object.keys(this.activities).join('');
    if (heartbeatString === this._prevHeartbeatString) {
      this._heartbeatCounter += 1;
    } else {
      this._heartbeatCounter = 1;
    }
    this._prevHeartbeatString = heartbeatString;
    if (this._heartbeatCounter >= 3) {
      IS_DEBUG_BUILD && logger.log('[Tracing] Transaction finished because of no change for 3 heart beats');
      this.setStatus('deadline_exceeded');
      this.setTag(FINISH_REASON_TAG, IDLE_TRANSACTION_FINISH_REASONS[0]);
      this.finish();
    } else {
      this._pingHeartbeat();
    }
  };
  /**
   * Pings the heartbeat
   */
  IdleTransaction.prototype._pingHeartbeat = function () {
    var _this = this;
    IS_DEBUG_BUILD && logger.log("pinging Heartbeat -> current counter: " + this._heartbeatCounter);
    setTimeout(function () {
      _this._beat();
    }, HEARTBEAT_INTERVAL);
  };
  return IdleTransaction;
}(Transaction);
export { IdleTransaction };
/**
 * Reset transaction on scope to `undefined`
 */
function clearActiveTransaction(hub) {
  if (hub) {
    var scope = hub.getScope();
    if (scope) {
      var transaction = scope.getTransaction();
      if (transaction) {
        scope.setSpan(undefined);
      }
    }
  }
}
