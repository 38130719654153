import { __assign, __extends } from "tslib";
import { getCurrentHub, Hub } from '@sentry/hub';
import { dropUndefinedKeys, isInstanceOf, logger } from '@sentry/utils';
import { IS_DEBUG_BUILD } from './flags';
import { Span as SpanClass, SpanRecorder } from './span';
/** JSDoc */
var Transaction = /** @class */function (_super) {
  __extends(Transaction, _super);
  /**
   * This constructor should never be called manually. Those instrumenting tracing should use
   * `Sentry.startTransaction()`, and internal methods should use `hub.startTransaction()`.
   * @internal
   * @hideconstructor
   * @hidden
   */
  function Transaction(transactionContext, hub) {
    var _this = _super.call(this, transactionContext) || this;
    _this._measurements = {};
    /**
     * The reference to the current hub.
     */
    _this._hub = getCurrentHub();
    if (isInstanceOf(hub, Hub)) {
      _this._hub = hub;
    }
    _this.name = transactionContext.name || '';
    _this.metadata = transactionContext.metadata || {};
    _this._trimEnd = transactionContext.trimEnd;
    // this is because transactions are also spans, and spans have a transaction pointer
    _this.transaction = _this;
    return _this;
  }
  /**
   * JSDoc
   */
  Transaction.prototype.setName = function (name) {
    this.name = name;
  };
  /**
   * Attaches SpanRecorder to the span itself
   * @param maxlen maximum number of spans that can be recorded
   */
  Transaction.prototype.initSpanRecorder = function (maxlen) {
    if (maxlen === void 0) {
      maxlen = 1000;
    }
    if (!this.spanRecorder) {
      this.spanRecorder = new SpanRecorder(maxlen);
    }
    this.spanRecorder.add(this);
  };
  /**
   * Set observed measurements for this transaction.
   * @hidden
   */
  Transaction.prototype.setMeasurements = function (measurements) {
    this._measurements = __assign({}, measurements);
  };
  /**
   * Set metadata for this transaction.
   * @hidden
   */
  Transaction.prototype.setMetadata = function (newMetadata) {
    this.metadata = __assign(__assign({}, this.metadata), newMetadata);
  };
  /**
   * @inheritDoc
   */
  Transaction.prototype.finish = function (endTimestamp) {
    var _this = this;
    // This transaction is already finished, so we should not flush it again.
    if (this.endTimestamp !== undefined) {
      return undefined;
    }
    if (!this.name) {
      IS_DEBUG_BUILD && logger.warn('Transaction has no name, falling back to `<unlabeled transaction>`.');
      this.name = '<unlabeled transaction>';
    }
    // just sets the end timestamp
    _super.prototype.finish.call(this, endTimestamp);
    if (this.sampled !== true) {
      // At this point if `sampled !== true` we want to discard the transaction.
      IS_DEBUG_BUILD && logger.log('[Tracing] Discarding transaction because its trace was not chosen to be sampled.');
      var client = this._hub.getClient();
      var transport = client && client.getTransport && client.getTransport();
      if (transport && transport.recordLostEvent) {
        transport.recordLostEvent('sample_rate', 'transaction');
      }
      return undefined;
    }
    var finishedSpans = this.spanRecorder ? this.spanRecorder.spans.filter(function (s) {
      return s !== _this && s.endTimestamp;
    }) : [];
    if (this._trimEnd && finishedSpans.length > 0) {
      this.endTimestamp = finishedSpans.reduce(function (prev, current) {
        if (prev.endTimestamp && current.endTimestamp) {
          return prev.endTimestamp > current.endTimestamp ? prev : current;
        }
        return prev;
      }).endTimestamp;
    }
    var transaction = {
      contexts: {
        trace: this.getTraceContext()
      },
      spans: finishedSpans,
      start_timestamp: this.startTimestamp,
      tags: this.tags,
      timestamp: this.endTimestamp,
      transaction: this.name,
      type: 'transaction',
      sdkProcessingMetadata: this.metadata
    };
    var hasMeasurements = Object.keys(this._measurements).length > 0;
    if (hasMeasurements) {
      IS_DEBUG_BUILD && logger.log('[Measurements] Adding measurements to transaction', JSON.stringify(this._measurements, undefined, 2));
      transaction.measurements = this._measurements;
    }
    IS_DEBUG_BUILD && logger.log("[Tracing] Finishing " + this.op + " transaction: " + this.name + ".");
    return this._hub.captureEvent(transaction);
  };
  /**
   * @inheritDoc
   */
  Transaction.prototype.toContext = function () {
    var spanContext = _super.prototype.toContext.call(this);
    return dropUndefinedKeys(__assign(__assign({}, spanContext), {
      name: this.name,
      trimEnd: this._trimEnd
    }));
  };
  /**
   * @inheritDoc
   */
  Transaction.prototype.updateWithContext = function (transactionContext) {
    var _a;
    _super.prototype.updateWithContext.call(this, transactionContext);
    this.name = (_a = transactionContext.name, _a !== null && _a !== void 0 ? _a : '');
    this._trimEnd = transactionContext.trimEnd;
    return this;
  };
  return Transaction;
}(SpanClass);
export { Transaction };
