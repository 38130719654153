export var TRACEPARENT_REGEXP = new RegExp('^[ \\t]*' +
// whitespace
'([0-9a-f]{32})?' +
// trace_id
'-?([0-9a-f]{16})?' +
// span_id
'-?([01])?' +
// sampled
'[ \\t]*$');
/**
 * Extract transaction context data from a `sentry-trace` header.
 *
 * @param traceparent Traceparent string
 *
 * @returns Object containing data from the header, or undefined if traceparent string is malformed
 */
export function extractTraceparentData(traceparent) {
  var matches = traceparent.match(TRACEPARENT_REGEXP);
  if (matches) {
    var parentSampled = void 0;
    if (matches[3] === '1') {
      parentSampled = true;
    } else if (matches[3] === '0') {
      parentSampled = false;
    }
    return {
      traceId: matches[1],
      parentSampled: parentSampled,
      parentSpanId: matches[2]
    };
  }
  return undefined;
}
